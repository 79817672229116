window.Pusher = require('pusher-js/dist/web/pusher.min.js');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
function getCookieInBoot(cname) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent (document.cookie);
    const ca = decodedCookie.split (';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
window.axios.defaults.headers.common['X-XSRF-TOKEN'] = getCookieInBoot('XSRF-TOKEN');
