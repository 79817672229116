import {eventBus} from '@/Mixins/eventbus';
import {format, utcToZonedTime} from 'date-fns-tz';
import {isThisWeek, isThisYear, isToday, isYesterday} from 'date-fns';
import {createPopper} from '@popperjs/core';

const appTooltips = {};

export const globalMixins = {
    getHumanDate: function (date) {
        // If the date is today: "Today at [Hour]"
        // If the date is yesterday: "Yesterday at [Hour]"
        // If the date is within the current week (excluding today and yesterday): "[Day of the week] at [Hour]"
        // If the date is not within the current week: "[Month] [Day] at [Hour]"
        // If the date is from last year: "[Month] [Day], [Year] at [Hour]"

        let dateTo = utcToZonedTime (date, 'America/Chicago');
        let startsWith = isToday (dateTo) ? `'Today'` :
            isYesterday (dateTo) ? `'Yesterday'` :
                isThisWeek (dateTo) ? 'cccc' :
                    isThisYear (dateTo) ? 'MMMM dd' : `MMMM dd',' yyyy`;

        return format (dateTo, startsWith + ' \'at\' h\':\'mm aa');
    },

    getFormattedDate (date, prefomattedDate = false, hideYear = false) {
        const MONTH_NAMES = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        const day = date.getDate ();
        const month = MONTH_NAMES[date.getMonth ()];
        const year = date.getFullYear ();

        if (prefomattedDate) {
            return `${prefomattedDate} at ${date.toLocaleString ('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            })}`;
        }

        if (hideYear) {
            return `${day} ${month}`;
        }

        return `${day} ${month}, ${year}`;
    },

    getHumanDateAsana (dateParam) {
        if (!dateParam) {
            return null;
        }

        const date = utcToZonedTime (dateParam, 'America/Chicago');
        const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
        const today = utcToZonedTime (new Date (), 'America/Chicago');
        const yesterday = new Date (today - DAY_IN_MS);
        const seconds = Math.round ((today - date) / 1000);
        const minutes = Math.round (seconds / 60);
        const hours = Math.round (minutes / 60);
        const days = Math.round (hours / 24);
        const isToday = today.toDateString () === date.toDateString ();
        const isYesterday = yesterday.toDateString () === date.toDateString ();
        const isThisMonth = today.getMonth () === date.getMonth ();
        const isThisYear = today.getFullYear () === date.getFullYear ();

        if (seconds < 90) {
            return 'about a minute ago';
        } else if (minutes < 60) {
            return `${minutes} minutes ago`;
        } else if (isToday) {
            if (hours === 1) {
                return `1 hour ago`;
            }
            return `${hours} hours ago`;
        } else if (isYesterday) {
            return this.getFormattedDate (date, 'Yesterday');  // Yesterday at 10:20
        } else if (isThisYear) {
            if (isThisMonth) {
                return `${days} days ago`;   // 5 days ago or 29 days ago
            }
            return this.getFormattedDate (date, false, true); // 18 Aug
        }
        return this.getFormattedDate (date); // 10 Jan, 2017
    },

    renderLimitedList: function (lengthList = 2, list) {
        if (list.length >= lengthList) {
            return list.slice (0, lengthList);
        }

        return list;
    },

    getValidationState (formField, errorField, successFieldLength = 2) {
        if (errorField && !formField) {
            return false
        }
        if (!formField) {
            return null
        }
        if (formField && formField.length && formField.length > successFieldLength) {
            return true
        }
    },

    isBasicUser (user) {
        return user.role_name === 'Basic';
    },

    isMarketscaleDomain (user) {
        return user.email.includes ('@marketscale.com');
    },

    isMarketscaleAdmin (roleId) {
        return roleId === 2;
    },

    isSuperAdmin (roleId, includeRoleIds = [2]) {
        const roleIds = [3];
        return [...roleIds, ...includeRoleIds].includes (roleId);
    },

    isOrgContributor (user) {
        return user.org_role === 'contributor';
    },

    isOrgStandard (user) {
        return user.org_role && user.org_role.includes ('standard');
    },

    isOrgAdmin (user) {
        return user.org_role === 'admin';
    },

    isOrgSuper (user) {
        return user.org_role === 'super' || (user.org_role === '' && this.isSuperAdmin (user.role_id));
    },

    isOrgAdminOrSuperAdmin (user) {
        return this.isOrgAdmin (user) || this.isOrgSuper (user);
    },

    getSafariVersion () {
        if (navigator.userAgent.includes ('Version/')) {
            const [version, patch] = navigator.userAgent.split ('Version/')[1].split ('.');

            if (version && patch[0]) {
                return version + '.' + patch[0];
            }
        }
        return null;
    },

    isSafariBrowser () {
        return !!(
            !navigator.userAgent.match (/Chrome/)
            && !navigator.userAgent.match (/CriOS/)
            && navigator.userAgent.match (/Safari/)
        );
    },

    isSafariDesktop () {
        return this.isSafariBrowser () && !this.isMobileOrTabletSafari ();
    },

    isMobileOrTabletSafari () {
        return !!(this.isSafariBrowser () && this.isIOS () && !!navigator.userAgent.match (/AppleWebKit/));
    },

    isIOS () {
        return !!navigator.userAgent.match (/(iPod|iPhone|iPad)/);
    },

    isIPadPro () {
        // /Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;
        return !!(navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test (navigator.platform));
    },

    isMacOrIOS () {
        return !!navigator.userAgent.match (/(Mac|iPod|iPhone|iPad)/)
    },

    getIOSChromeVersion () {
        if (navigator.userAgent.includes ('CriOS/')) {
            const [version, patch] = navigator.userAgent.split ('CriOS/')[1].split ('.');

            if (version && patch[0]) {
                return version + '.' + patch[0];
            }
        }
        return null;
    },

    isChromeIOS () {
        return !!navigator.userAgent.match ('CriOS');
    },

    isChrome () {
        return !!navigator.userAgent.match (/Chrom(e|ium)\/([0-9]+)\./);
    },

    isFirefox () {
        return !!navigator.userAgent.toLowerCase ().match (/firefox/);
    },

    getFirefoxVersion () {
        const parts = navigator.userAgent.match (/Firefox\/([0-9]+)\./);
        return parts ? parseInt (parts[1], 10) : false;
    },

    isMobileOrTabletDevice () {
        return !!navigator.userAgent.match (/(Android|iPod|iPhone|iPad)/);
    },

    isAndroid () {
        return !!navigator.userAgent.match (/Android/);
    },

    async getPermissionStatus (permissionName, getUpdatedStatus) {
        if (navigator.permissions && navigator.permissions.query) {
            try {
                const status = await navigator.permissions.query ({name: permissionName});
                if (getUpdatedStatus) {
                    status.onchange = function () {
                        getUpdatedStatus (permissionName, this.state)
                    };
                }
                return status.state || null;
            } catch (e) {
                console.log ('error occurred when try to get permission status ', e);
                return null;
            }
        }
        return null;
    },

    async getPermissionDeniedMessage (err) {
        const statusCamera = await this.getPermissionStatus ('camera');
        const statusMicrophone = await this.getPermissionStatus ('microphone');

        if (statusCamera && statusCamera === 'denied') {
            return 'Studio was denied access to your camera by your browser. Please reload the page and enable permissions to record your content.';
        } else if (statusMicrophone && statusMicrophone === 'denied') {
            return 'Studio was denied access to your microphone by your browser. Please reload the page and enable permissions to record your content.';
        } else if (err && err.toString ().toLowerCase ().includes ('permission denied')) {
            return 'Studio was denied access to your microphone and camera by your browser. Please reload the page and enable permissions to record your content.';
        }

        return '';
    },

    onCopyToClipboard (value, title = 'Link copied to clipboard') {
        try {
            if (!value) {
                throw 'Value is empty!';
            }

            if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText (value).then (function () {
                }, function (err) {
                    throw err;
                });
            } else {
                const textarea = document.createElement ('textarea');
                textarea.className = 'temp-textarea';
                textarea.style.opacity = '0';
                document.body.append (textarea);
                const tempInput = document.querySelector ('.temp-textarea');
                tempInput.value = value;
                tempInput.select ();
                tempInput.focus ();

                setTimeout (() => {
                    tempInput.remove ();
                }, 0);

                document.execCommand ('copy');
            }

            this.$notify ({group: 'notification', title: title});
        } catch (err) {
            eventBus.emit ('AlertModal', {
                title: `Something went wrong`,
                text: `Oops, unable to copy.`,
                showIcon: 'yellow',
                button2: 'OK',
            });
        }
    },

    getSizeInMB (size) {
        return (size / 1024 / 1024).toFixed (2);
    },

    getAssignedTags (tags) {
        if (tags && tags.length) {
            return tags.filter ((tag) => tag.name.trim ()).map (tag => tag.name);
        }
        return [];
    },

    // onBlurLayout() {
    //     let modalShow = document.querySelector(".c-BlurLayout.show");
    //     if (modalShow) {
    //         document.querySelector(".o-app-layout").style.filter = 'blur(5px)';
    //     }
    // },
    // onBlurLayoutRemove() {
    //     const layout = document.querySelector(".o-app-layout");
    //     if (layout) {
    //         layout.style.filter = 'none';
    //     }
    // },

    onHistoryBack (url = null, refresh = false) {
        if (history.length > 2) {
            let prevPage = window.location.href;
            history.back ();

            if (refresh) {
                setTimeout (function () {
                    this.$inertia.get (window.location.href);
                }, 100)
            }

            setTimeout (function () {
                if (window.location.href == prevPage) {
                    this.$inertia.get (this.route ('dashboard'))
                }
            }, 500);
        } else if (url) {
            this.$inertia.get (url);
        } else {
            this.$inertia.get (this.route ('dashboard'))
        }
    },

    resetFocus (id) {
        document.activeElement.blur ();
        let parent = document.getElementById (id);
        let input = document.createElement ('input');
        input.style.position = 'fixed';
        input.setAttribute ('type', 'text');
        if (parent) {
            parent.insertBefore (input, parent.firstChild);
        }
        input.focus ();
        if (parent) {
            parent.removeChild (input);
        }
    },

    toggleHideDropdownByContainerId (id, styleValue) {
        const dropdown = document.querySelector (`#${id} .ti-autocomplete`);

        if (dropdown) {
            dropdown.style.display = styleValue;
        }
    },

    toggleDropdown ({id, items, hideWhenCountEqual = 1, removeListener = false} = {}) {
        if (removeListener) {
            return this.openDropdownOnFocus (id, true);
        }
        // console.log('toggle dropdown')
        // if ([items].length === hideWhenCountEqual) {
        //     this.toggleHideDropdownByContainerId(id, 'none');
        //     this.resetFocus(id);
        //     this.openDropdownOnFocus(id);
        // }
        // if ([items].length === 0) {
        //     this.toggleHideDropdownByContainerId(id, 'block');
        // }

        this.toggleHideDropdownByContainerId (id, 'none');
        this.resetFocus (id);
        this.openDropdownOnFocus (id);
    },

    openDropdownOnFocus (id, remove = false) {
        const input = document.querySelector (`#${id} .ti-new-tag-input`);
        const handler = () => {
            const dropdown = document.querySelector (`#${id} .ti-autocomplete`);
            if (dropdown) {
                dropdown.style.display = 'block';
            }
        };
        if (input && remove) {
            return input.removeEventListener ('focus', handler)
        }
        if (input) {
            input.addEventListener ('focus', handler)
        }
    },

    getColorDependsOnBackground (color) {
        if (color) {
            const rgbValueArr = this.hexToRgb (color);

            const colorValue = Math.round (((parseInt (rgbValueArr[0]) * 299) +
                (parseInt (rgbValueArr[1]) * 587) +
                (parseInt (rgbValueArr[2]) * 114)) / 1000);

            return (colorValue > 125) ? 'black' : 'white';
        }
        return '';
    },

    // getColorDependsOnBrandBackground(color) { /*NOTE: if bg color is color of the brand */
    //     let colorVal = color ? color : getComputedStyle(document.documentElement).getPropertyValue('--primary').trim();
    //     return this.getColorDependsOnBackground(colorVal);
    // },

    hexToRgb (hex) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec (hex);
        return result ? [
            parseInt (result[1], 16),
            parseInt (result[2], 16),
            parseInt (result[3], 16)
        ] : null;
    },

    onUpdateBottomPositionFreshchat (value) {
        if (document.body.querySelector ('#hubspot-messages-iframe-container')) {
            document.body.querySelector ('#hubspot-messages-iframe-container').style.cssText = document.body.querySelector ('#hubspot-messages-iframe-container').style.cssText + `bottom: ${value}px !important;`;
        } else {
            setTimeout (() => this.onUpdateBottomPositionFreshchat (value), 100);
        }
    },

    onHideFreshchat () {
        if (window?.HubSpotConversations?.widget?.status ()?.loaded) {
            window.HubSpotConversations.widget.remove ();
        } else {
            setTimeout (this.onHideFreshchat, 100);
        }
    },

    onShowFreshchat () {
        if (window?.HubSpotConversations?.widget?.status ()?.loaded === false) {
            window.HubSpotConversations.widget.load ();
        } else {
            setTimeout (this.onShowFreshchat, 100);
        }
    },

    onOpenFreshChat () {
        if (window?.HubSpotConversations?.widget?.status ()?.loaded) {
            window.HubSpotConversations.widget.open ();
        } else if (window?.HubSpotConversations?.widget?.status ()?.loaded === false) {
            window.HubSpotConversations.widget.load ();
            setTimeout (this.onOpenFreshChat, 100);
        } else {
            setTimeout (this.onOpenFreshChat, 100);
        }
    },

    isDevMode () {
        return this.$page.props?.appEnv === 'sandbox' || this.$page.props?.appEnv === 'local';
    },

    isUserImg (url) {
        return !!(url?.length && /\.(jpeg?|jpg?|jpe?|png?|heic?|heif?|bmp?|svg?|webp?)$/i.test (url));
    },

    onOpenSubmissionIssueModal (onSubmitAgainFun = () => {
    }) {
        eventBus.emit ('AlertModal', {
            title: `SUBMISSION ISSUE`,
            text: `We've saved your progress, but it seems there's a technical issue preventing the submission of your recording. <br><br> If the problem persists, please don't hesitate to contact our <button id="customerSupportBtn" class="c-alert-modal__link">Customer Support</button>`,
            showIcon: 'red',
            additionalModalClass: 'c-alert-modal--submission-issue',
            button1: 'Close',
            button2: 'Submit again',
            onButton2Click: () => onSubmitAgainFun (),
        });

        setTimeout (() => {
            let customerSupportBtn = document.getElementById ('customerSupportBtn');
            if (customerSupportBtn) {
                customerSupportBtn.addEventListener ('click', this.onOpenFreshChat);
            }
        }, 1500);
    },

    onShowTooltip (targetId, placement = 'top', modifiers = []) {
        const buttonElement = document.getElementById (targetId);
        const tooltipElement = document.getElementById (`tooltip-for-${targetId}`);

        if (buttonElement && tooltipElement) {
            // tooltipElement.setAttribute('data-show', '');
            tooltipElement.classList.add ('show');

            appTooltips[targetId] = createPopper (buttonElement, tooltipElement, {
                // createPopper(buttonElement, tooltipElement, {
                placement: placement,
                modifiers: modifiers,
            });

            appTooltips[targetId]?.update ()
        }
    },

    onHideTooltip (targetId) {
        appTooltips[targetId]?.destroy ();
        const tooltipElement = document.getElementById (`tooltip-for-${targetId}`);
        if (tooltipElement) {
            // tooltipElement.removeAttribute('data-show');
            tooltipElement.classList.remove ('show');
        }
    },

    onDropdownHide (dropdownBtnId) {
        let dropdownElement = document.getElementById (dropdownBtnId);
        if (dropdownElement) {
            // dropdownElement.setAttribute('aria-expanded', 'false');
            // dropdownElement.classList.remove('show');
            // dropdownElement.nextElementSibling.classList.remove('show');
            // dropdownElement.nextElementSibling.style = '';
            // dropdownElement.nextElementSibling.removeAttribute('data-popper-placement');

            dropdownElement.click ();
        }
    },
};
