require('./bootstrap');
import Vue from 'vue';
import { App as InertiaApp, plugin as InertiaPlugin, Link } from '@inertiajs/inertia-vue';
import Dropdown from 'bootstrap/js/dist/dropdown'
import Modal from 'bootstrap/js/dist/modal'
import Collapse from  'bootstrap/js/dist/collapse';
import { globalMixins } from '@/Mixins/globalMixins';
import Notifications from 'vue-notification';
import * as Sentry from "@sentry/vue";

import "plyr/dist/plyr.css"

Vue.component("inertia-link", Link);
Vue.prototype.$dropdown = Dropdown;
Vue.prototype.$modal = Modal;
Vue.prototype.$collapse = Collapse;
Vue.mixin({ methods: { route, ...globalMixins } });
Vue.use(InertiaPlugin);
Vue.use(Notifications);

const app = document.getElementById('app');

Sentry.init({
    Vue,
    dsn: "https://33238e13685cb2653b30eaeb691cc29b@o4507346095636480.ingest.us.sentry.io/4507346986532864", // Studio MarketScale dns
    integrations: [
      Sentry.browserTracingIntegration({ InertiaApp }),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration(),
    ],
    environment: JSON.parse(app.dataset.page)?.props?.appEnv,
    enabled: ( JSON.parse(app.dataset.page)?.props?.appEnv !== 'local' ) && ( JSON.parse(app.dataset.page)?.props?.appEnv !== 'sandbox' ),

    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/(sandbox|staging)\.studio\.marketscale\.com\/api/],

    // Capture Replay for 5% of all sessions, plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
});

new Vue({
    render: (h) =>
        h(InertiaApp, {
            props: {
                initialPage: JSON.parse(app.dataset.page),
                resolveComponent: (name) => import(`./Pages/${name}`).then(module => module.default),
            },
        }),
}).$mount(app);
